<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">
        {{ user == 1 ? "일반" : "지자체" }} 회원 관리
      </h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <!-- <div class="mb_30">
          <base-select :options="state" v-model="stateValue" />
        </div> -->
        <table>
          <colgroup>
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 158px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">가입방법</td>
            <td class="box_style_02">
              {{ registerType }}
            </td>
            <td class="box_style_02 list_title_style">이름</td>
            <td class="box_style_02">
              {{ name }}
            </td>
            <td class="box_style_02 list_title_style">아이디</td>
            <td class="box_style_02">
              {{ loginid }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">이메일주소</td>
            <td class="box_style_02">
              {{ email }}
            </td>
            <td class="box_style_02 list_title_style">가입 일시</td>
            <td class="box_style_02">
              {{ registerDate }}
            </td>
            <td class="box_style_02 list_title_style">탈퇴 일시</td>
            <td class="box_style_02">
              {{ withdrawal.date }}
            </td>
          </tr>
          <template v-if="user != 1">
            <tr class="line_style">
              <td class="box_style_02 list_title_style">소속/단체명</td>
              <td class="box_style_02">
                {{ corporation.name }}
              </td>
              <td class="box_style_02 list_title_style">사업자 등록번호</td>
              <td class="box_style_02" colspan="1">
                {{ corporation.number }}
              </td>
              <td class="box_style_02 list_title_style">최종 로그인 일시</td>
              <td class="box_style_02" colspan="1" style="width: 790px">
                <span v-if="lastLogin">
                  {{ lastLogin }}
                </span>
                <span v-else>-</span>
              </td>
            </tr>
          </template>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">이용약관</td>
            <td class="box_style_02">
              {{ termsAgree }}
            </td>
            <td class="box_style_02 list_title_style">개인정보처리</td>
            <td class="box_style_02">
              {{ privacyAgree }}
            </td>
            <td class="box_style_02 list_title_style">마케팅수신</td>
            <td class="box_style_02" colspan="3">
              {{ marketingAgree }}
            </td>
            <!-- <td class="box_style_02 list_title_style">제3자제공</td>
            <td class="box_style_02" style="width: 790px">
              {{ thirdpartyAgree }}
            </td> -->
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">위치정보이용약관</td>
            <td class="box_style_02">
              {{ locationAgree }}
            </td>
            <td class="box_style_02 list_title_style">개인정보 제3자 제공</td>
            <td class="box_style_02" colspan="3">
              {{ thirdpartyAgree }}
            </td>
          </tr>
          <tr class="line_style" v-if="user == 1">
            <td class="box_style_02 list_title_style">최종 로그인 일시</td>
            <td class="box_style_02" colspan="5" style="width: 790px">
              <span v-if="lastLogin">
                {{ lastLogin }}
              </span>
              <span v-else>-</span>
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">탈퇴사유</td>
            <td class="box_style_02" colspan="5" style="width: 790px">
              <span v-if="withdrawal.type != '' || withdrawal.content != ''">
                {{ withdrawal.type }} :{{ withdrawal.content }}
              </span>
              <span v-else>-</span>
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="modifyState">확인</base-button>
          <!-- <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          > -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
// import BaseSelect from "@/components/core/BaseSelect.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    user: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      registerType: "",
      name: "",
      loginid: "",
      email: "",
      userState: { name: "", value: "" },
      stateValue: "",
      lastLogin: "",
      registerDate: "",
      termsAgree: "",
      privacyAgree: "",
      thirdpartyAgree: "",
      marketingAgree: "",
      locationAgree: "",
      withdrawal: {
        content: "",
        type: "",
        date: "",
      },
      corporation: {
        name: "",
        number: "",
      },
      state: [
        { name: "정상", value: "정상" },
        { name: "탈퇴", value: "탈퇴" },
        // { name: "정상", value: 1 },
        // { name: "탈퇴", value: 9 },
        { name: "휴면예정", value: "휴면예정" },
        { name: "휴면", value: "휴면" },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.userFindone(this.id).then(
        (response) => {
          const { data } = response;
          this.registerType = data.registerType ? data.registerType : "-";
          this.name = data.name ? data.name : "-";
          // TODO 마지막 로그인 일시
          this.lastLogin = data.latestLoginDate ? data.latestLoginDate : "-";
          this.loginid = data.loginid ? data.loginid : "-";
          this.email = data.email ? data.email : "-";
          this.registerDate = data.registerDate
            ? this.$d(new Date(data.registerDate), "long")
            : "-";
          this.withdrawal.content = data.withdrawal.content;
          this.withdrawal.type = data.withdrawal.type;
          this.withdrawal.date = data.withdrawal.date
            ? data.withdrawal.date
            : "-";
          this.corporation.name = data.corporation.name
            ? data.corporation.name
            : "-";
          this.corporation.number = data.corporation.number
            ? data.corporation.number
            : "-";
          this.stateValue = data.state;

          if (data.state) {
            switch (data.state) {
              case "정상":
                this.userState = { name: "정상", value: 1 };
                break;
              case "탈퇴":
                this.userState = { name: "탈퇴", value: 9 };
                break;
              case "휴면예정":
                this.userState = { name: "휴면예정", value: "휴면예정" };
                break;
              case "휴면":
                this.userState = { name: "휴면", value: "휴면" };
                break;
            }
          }

          if (data.termsAgree == 1) {
            this.termsAgree = "동의";
          } else if (data.termsAgree == 0) {
            this.termsAgree = "동의안함";
          }
          if (data.privacyAgree == 1) {
            this.privacyAgree = "동의";
          } else if (data.privacyAgree == 0) {
            this.privacyAgree = "동의안함";
          }
          if (data.marketingAgree == 1) {
            this.marketingAgree = "동의";
          } else if (data.marketingAgree == 0) {
            this.marketingAgree = "동의안함";
          }
          if (data.thirdpartyAgree == 1) {
            this.thirdpartyAgree = "동의";
          } else if (data.thirdpartyAgree == 0) {
            this.thirdpartyAgree = "동의안함";
          }
          if (data.locationAgree == 1) {
            this.locationAgree = "동의";
          } else if (data.locationAgree == 0) {
            this.locationAgree = "동의안함";
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    // TODO 회원 탈퇴
    async removeFetch() {
      await client.generalUserRemove(this.id).then(
        // (response) =>{

        // },
        (error) => {
          console.log(error);
        }
      );
    },

    modifyState() {
      if (this.stateValue == "휴면" || this.stateValue == "휴면예정") {
        alert("휴면 상태에 대한 관리자의 권한이 없습니다");
      } else {
        // TODO 회원 정상화 및 탈퇴 처리
        this.$emit("close");
      }
    },
  },
};
</script>
