<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <!-- <span>
            <label>가입방법</label>
            <base-select
              :options="search.registerType.option"
              v-model="search.registerType.value"
              @input="fetch(1)"
              placeholder="선택"
            />
          </span> -->
          <span>
            <label>상태</label>
            <base-select
              :options="search.state.option"
              v-model="search.state.value"
              @input="fetch(1)"
            />
          </span>
          <span>
            <label>가입일</label>
            <date-picker
              v-model="search.startDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="search.endDate.value"
              :popover="{ visibility: 'click' }"
              :min-date="search.startDate.value"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
          </span>
          <span>
            <label>검색어</label>
            <base-select
              :options="search.basic.option"
              v-model="search.basic.value"
            />
            <input
              type="text"
              v-model="search.q"
              placeholder="검색어를 입력해 주세요."
            />
            <base-button type="primary" nativeType="submit">검색</base-button>
          </span>
        </div>
      </form>
    </section>
    <section class="cont_02">
      <div class="total_box addnumbox">
        <div>
          <div class="srch_total pl_40">
            <span class="srch_total_title">검색 :</span>
            <span class="total_count">{{ searchTotalItems }}</span>
            <span>명</span>
          </div>
          <div class="total ml_31">
            <span class="total_title">전체 :</span>
            <span class="total_count">{{ $n(totalItems) }}</span>
            <span>건</span>
          </div>
        </div>
        <span class="pr_40">
          <base-select
            :options="search.size.option"
            v-model="search.size.value"
            @input="fetch(1)"
          />
        </span>
      </div>
      <div class="table_area">
        <div>
          <base-table
            :useNo="true"
            :totalItems="totalItems"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :loading="contentLoading"
            :fields="fields"
            :items="items"
            @click="onTableClick"
            :clickedNum="clickedNum"
            @sort="onSortClick"
            :isEdit="false"
          />
        </div>
        <base-pagination
          :total="totalItems"
          :loading="contentLoading"
          :currentPage="currentPage"
          :pageCount="totalPages"
          :perPage="pageSize"
          @change="fetch"
        />
      </div>
      <!-- <div class="btn_right_wrapper">
        <base-button type="primary">신규등록</base-button>
      </div> -->
      <div class="btn_right_wrapper">
        <base-button
          type="download"
          @click.prevent.stop="
            download('advertisement?download=xls', '지자체 회원 현황', 'xls')
          "
          :disabled="btnDisabled"
          >{{ downloadText }}</base-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseSelect from "@/components/core/BaseSelect.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BasePagination from "@/components/core/BasePagination.vue";
// import Dialog from "@/components/Dialog.vue";
import DetailUser from "@/components/DetailUser.vue";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "UserUserClient",
  components: {
    BaseButton,
    BaseSelect,
    BaseTable,
    BasePagination,
  },
  data() {
    return {
      currentSort: "",
      clickedNum: 0,
      searchTotalItems: 0,
      downloadText: "엑셀 다운로드",
      btnDisabled: false,
      fields: [
        // {
        //   key: "registerType",
        //   width: "120px",
        //   label: "가입방법",
        // },
        {
          key: "corporation.name",
          width: "130px",
          label: "소속/단체명",
          useClick: true,
        },
        {
          key: "name",
          width: "110px",
          label: "이름",
          useClick: true,
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "loginid",
          width: "110px",
          label: "아이디",
          useClick: true,
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "email",
          width: "180px",
          label: "이메일",
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "registerDate",
          width: "100px",
          label: "가입일시",
          formatter: {
            name: "date",
            type: "short",
          },
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "latestLoginDate",
          width: "100px",
          label: "최종 로그인 일시",
          formatter: {
            name: "date",
            type: "short",
          },
        },
        {
          key: "withdrawalDate",
          width: "120px",
          label: "탈퇴일시",
          formatter: {
            name: "date",
            type: "long",
          },
        },
        /* {
          key: "termsAgree",
          width: "80px",
          label: "이용약관",
        },
        {
          key: "privacyAgree",
          width: "90px",
          label: "개인정보처리",
        },
        지자체는 마케팅이 제3자 제공동의?
        {
          key: "marketingAgree",
          width: "80px",
          label: "제3자 제공동의",
        },
        {
          key: "thirdpartyAgree",
          width: "80px",
          label: "제3자제공",
        },
        {
          key: "locationAgree",
          width: "110px",
          label: "위치정보이용약관",
        },*/
        {
          key: "state",
          width: "80px",
          label: "상태",
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      search: {
        // registerType: {
        //   event: true,
        //   option: [
        //     { name: "전체", value: "" },
        //     { name: "이메일", value: "email" },
        //     { name: "페이스북", value: "facebook" },
        //   ],
        //   value: "",
        // },
        size: {
          event: true,
          option: [
            {
              name: "10개씩",
              value: 10,
            },
            {
              name: "20개씩",
              value: 20,
            },
            {
              name: "30개씩",
              value: 30,
            },
            {
              name: "40개씩",
              value: 40,
            },
            {
              name: "50개씩",
              value: 50,
            },
          ],
          value: 10,
        },
        state: {
          event: true,
          option: [
            { name: "정상", value: 1 },
            { name: "탈퇴", value: 9 },
          ],
          value: "",
        },
        basic: {
          option: [
            { name: "이름", value: "name" },
            { name: "이메일", value: "email" },
            { name: "아이디", value: "loginid" },
          ],
          value: "",
        },
        startDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        endDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        q: "",
      },
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    download(url, name, type, params) {
      this.downloadText = "다운로드 중";
      this.btnDisabled = true;
      params = {
        page: this.currentPage,
        // size: this.pageSize,
        size: this.totalItems,
        download: "xls",
      };
      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      }
      url = `${API_URL}${SERVICE_API_URL}/user/client`;
      File.download(url, name, type, params, (response) => {
        console.log(response);
        // if (response.status == 200) {
        this.btnDisabled = false;
        this.downloadText = "엑셀 다운로드";
        // }
      });
    },
    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },

    onTableClick(params) {
      this.$modal.show(
        DetailUser,
        {
          user: 2,
          id: params.item.id,
        },
        {
          width: 978,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },

    onSortClick(value) {
      if (value.item.key != this.currentSort) {
        this.clickedNum = 0;
        for (let i = 0; i < this.fields.length; i++) {
          this.fields[i].clickedNum = 0;
        }
      }
      this.currentSort = value.item.key;
      if (this.currentSort.includes(".")) {
        const sortString = this.currentSort.split(".");
        this.currentSort =
          sortString[0] +
          sortString[1][0].toUpperCase() +
          sortString[1].slice(1);
      }
      this.clickedNum = this.clickedNum < 2 ? this.clickedNum + 1 : 0;
      this.fields[value.index].clickedNum = this.clickedNum;
      if (!this.contentLoading) {
        this.fetch(1);
      }
    },

    async fetch(page = 1) {
      console.log("fetch123");
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };
      if (this.currentSort) {
        if (this.clickedNum == 1) {
          params.sort = `${this.currentSort}.asc`;
        } else if (this.clickedNum == 2) {
          params.sort = `${this.currentSort}.desc`;
        }
      }

      if (this.search.startDate.value && this.search.endDate.value) {
        params.startDate = this.$date(
          new Date(this.search.startDate.value)
        ).format("YYYY-MM-DD");
        params.endDate = this.$date(new Date(this.search.endDate.value)).format(
          "YYYY-MM-DD"
        );
      }

      Object.entries(this.search).forEach((row) => {
        if (row[1].event) {
          if (row[1].value.length !== 0) {
            params[row[0]] = row[1].value;
            this.pageSize = Number(row[1].value);
          }
        }
      });

      if (this.search.q && this.search.basic.value) {
        params[this.search.basic.value] = this.search.q;
      } else if (0 < this.search.q.length) {
        params.important = this.search.q;
        /*
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "검색어 항목을 선택해 주세요.",
          },
          {
            width: 400,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        return;
        */
      }
      await client.clientUserFindAll(params).then(
        (response) => {
          const { data } = response;
          console.log(data);
          this.items = data.items;
          this.totalPages = data.totalPages;
          this.contentLoading = false;
          if (this.totalItems == 0) {
            this.totalItems = data.totalItems;
          }

          this.items.forEach((item) => {
            if (item.termsAgree == 1) {
              item.termsAgree = "동의";
            } else if (item.termsAgree == 0) {
              item.termsAgree = "동의안함";
            }
            if (item.privacyAgree == 1) {
              item.privacyAgree = "동의";
            } else if (item.privacyAgree == 0) {
              item.privacyAgree = "동의안함";
            }
            if (item.marketingAgree == 1) {
              item.marketingAgree = "동의";
            } else if (item.marketingAgree == 0) {
              item.marketingAgree = "동의안함";
            }
            if (item.thirdpartyAgree == 1) {
              item.thirdpartyAgree = "동의";
            } else if (item.thirdpartyAgree == 0) {
              item.thirdpartyAgree = "동의안함";
            }
            if (item.locationAgree == 1) {
              item.locationAgree = "동의";
            } else if (item.locationAgree == 0) {
              item.locationAgree = "동의안함";
            }
          });

          if (
            0 < this.search.q.length ||
            (this.search.startDate.value && this.search.endDate.value) ||
            0 < this.search.state.value
          ) {
            this.searchTotalItems = data.totalItems;
          } else if (!this.search.state.value) {
            this.searchTotalItems = 0;
          }
        },
        () => {
          this.items = [];
          // this.totalItems = 0;
          this.totalPages = 0;
          this.searchTotalItems = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
